import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { heroData } from "../../data"
import { backendHost } from "../../config"
// const backendHost = 'http://localhost:1337';
// css
import "./hero-section.css"
// images
import Banner4 from "../../assets/images/home/banner-4.jpg"
import Banner4Ar from "../../assets/images/home/banner-4-ar.jpg"
import Banner6 from "../../assets/images/home/banner-6.jpg"

const HeroSection = ({ lang }) => {
  const HomeBannerImage = lang === "ar" ? Banner4Ar : Banner4
  const requiredDateTime = "2020-07-20T01:58:14+04:00" // actual time for launch
  const [showCountdown, setShowCountdown] = useState(true)

  const CheckTime = () => {
    const currentTime = new Date()
    const required = new Date(requiredDateTime)

    if (currentTime.getTime() >= required.getTime()) {
      setShowCountdown(false)
    } else {
      setTimeout(CheckTime, 500)
    }
  }

  let heroDataMutated = heroData
    .map(datum => {
      if (lang == "ar") {
        datum.heading = datum.arabic_heading
        datum.description = datum.arabic_description
        datum.url = datum.arabic_url ? datum.arabic_url : datum.url
      }
      return datum
    })
    .sort((a, b) => (a.id ? a.id - b.id : a.strapiId - b.strapiId))

  const convertLineBreaks = txt => (
    <span>
      {txt.split("<br />").map(s => (
        <span>
          {s}
          <br />
        </span>
      ))}
    </span>
  )

  useEffect(() => {
    CheckTime()
  }, [])

  const lng = (en, ar) => (lang === "en" ? en : ar)

  return (
    <div
      className="uk-position-relative uk-visible-toggle uk-light"
      data-tabindex="-1"
      data-uk-slider="center: true; autoplay: true"
      style={{
        backgroundImage: `url(${HomeBannerImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <ul
        className="uk-slider-items uk-grid uk-grid-match"
        data-uk-height-viewport="offset-top: false;"
      >
        {/* Hardcoded banners start */}
        {/* <li
          className="uk-width-1-1"
          style={{ cursor: "pointer", marginTop: "100px" }}
          onClick={() => {
            if (typeof window !== "undefined")
              window.open(
                `https://www.emiratesmarsmission.ae${
                  (lang === "ar" && "/ar") || ""
                }/gen-hope/programmes/research-experience-for-undergraduates-programme`
              )
          }}
        >
          <div className="uk-cover-container cursor-pointer">
            <img src={Banner5} alt="" data-uk-cover style={{ width: "100%" }} />
            <div className="uk-position-left uk-position-center uk-panel hero-text-container banner-5-heading-container">
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              >
                <span
                  className="uk-heading-xs uk-text-center martian-b-thai-important opacity-1-important"
                  style={{
                    zIndex: "2",
                    color: "#fff",
                    maxWidth: "1200px",
                    width: "90%",
                  }}
                >
                  {lng(
                    "Registration is now open for the",
                    <span className="font-cairo uk-text-bold">
                      التسجيل مفتوح الآن
                    </span>
                  )}{" "}
                  {lng(
                    "Research Experience for Undergraduates (REU) Programme 2022",
                    <span className="font-cairo uk-text-bold">
                      لبرنامج أبحاث علوم الفضاء للطلبة الجامعيين 2022
                    </span>
                  )}
                </span>

                <h2
                  className="uk-heading-2xs martian-b-thai-important uk-text-center"
                  style={{ width: "90%" }}
                >
                  {lng(
                    "Click here to view the program details and register",
                    <span className="font-cairo uk-text-bold">
                      اضغط هنا للمزيد من المعلومات عن البرنامج والتسجي
                    </span>
                  )}
                </h2>
              </div>
            </div>
          </div>
        </li> */}
        <li
          className="uk-width-1-1"
          style={{ cursor: "pointer", marginTop: "100px" }}
          onClick={() => {
            if (typeof window !== "undefined")
              window.open("https://sdc.emiratesmarsmission.ae/")
          }}
        >
          <div className="uk-cover-container">
            <img
              src={lang === "ar" ? Banner4Ar : Banner4}
              alt=""
              data-uk-cover
            />
            <div className="uk-position-left uk-position-center uk-panel ph5 hero-text-container">
              <div style={{ width: "100%", height: "20px" }} />
            </div>
          </div>
        </li>
        {/* Hardcoded banners end */}
        {heroDataMutated.map(datum => {
          datum.heading = datum.heading || ""
          return (
            <li className="uk-width-1-1">
              <div className="uk-cover-container">
                {datum.type == "image" ? (
                  <img src={datum.media.publicURL} alt="" data-uk-cover />
                ) : datum.type == "video" ? (
                  <video
                    src={datum.media.publicURL}
                    width="1599"
                    height="900"
                    autoplay="1"
                    playsinline="1"
                    muted="1"
                    loop="1"
                    data-uk-cover
                  ></video>
                ) : (
                  ""
                )}
                <div className="uk-position-left uk-position-center uk-panel ph5 hero-text-container">
                  {datum.heading.trim() ? (
                    <>
                      <h1 className="uk-heading-medium@s uk-heading-small uk-visible@s">
                        {convertLineBreaks(datum.heading)}
                      </h1>
                      <div className="heading-underline-light"></div>
                    </>
                  ) : (
                    <div style={{ width: "100%", height: "20px" }} />
                  )}
                  {datum.description ? (
                    <p className="mv4 text-light">{datum.description}</p>
                  ) : (
                    ""
                  )}
                  {datum.url ? (
                    <Link to={datum.url}>
                      <span className="uk-text-bold">
                        {lang == "ar" ? "سفراء الأمل" : "LEARN MORE"}
                      </span>
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </li>
          )
        })}
      </ul>
      {/* <div
        className="flex flex-column mb6-ns uk-position-bottom uk-panel"
        style={{
          maxWidth: "1300px",
          marginLeft: "auto",
          marginRight: "auto",
          padding: "20px 0px 0px 0px",
          margin: "auto",
        }}
      >
        <div className="mv4 ph4-ns">
          <div>
            <ul
              className="uk-slider-nav uk-flex-center uk-margin custom-dot-nav"
              // style={{ marginBottom: "0px" }}
            >
              {heroData.map((e, index) => (
                <li uk-slider-item={`${index}`}>
                  <a href="#">{index < 9 ? "0" + (index + 1) : index + 1}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div> */}
      <a
        class="uk-position-center-left uk-position-small uk-visible@s icon-lg"
        href="#"
        data-uk-slidenav-previous
        data-uk-slider-item="previous"
      ></a>
      <a
        class="uk-position-center-right uk-position-small uk-visible@s icon-lg"
        href="#"
        data-uk-slidenav-next
        data-uk-slider-item="next"
      ></a>
    </div>
  )
}

export default HeroSection
