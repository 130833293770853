import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroSection from "./index/hero-section"
import imgHomeSectionOne from "../assets/images/home/home-section-1.png"
import imgHomeSectionTwo from "../assets/images/home/home-section-2.png"
import imgMarsBlur from "../assets/images/home/mars-blur.png"
import imgEarthBlur from "../assets/images/home/earth.png"
import imgVideoPlaceholder from "../assets/images/video-placeholder.png"
import imgPeopleOne from "../assets/images/home/people-img-1.png"
import imgPeopleTwo from "../assets/images/home/people-img-2.png"
import imgPeopleThree from "../assets/images/home/people-img-3.png"
import imgPeopleFour from "../assets/images/home/people-img-4.jpg"
import imgPeopleFive from "../assets/images/home/people-img-5-new.jpg"
import imgPeopleSix from "../assets/images/home/people-img-6-new.jpg"
import imgPeopleSeven from "../assets/images/home/people-img-7.jpg"
import imgHomeSatellite from "../assets/images/home/home-satellite-2-ar.png"
import imgFactOne from "../assets/images/home/fact-1-w.jpg"
import imgFactTwo from "../assets/images/home/fact-2-w.jpg"
import imgFactThree from "../assets/images/home/fact-3-w.jpg"
import imgFactFour from "../assets/images/home/fact-4-w.jpg"
import imgFactFive from "../assets/images/home/fact-5-w.jpg"
import imgFactSix from "../assets/images/home/fact-6-w.jpg"
import imgFactSeven from "../assets/images/home/fact-7-w.jpg"
import imgFactEight from "../assets/images/home/fact-8-w.jpg"
import imgFactNine from "../assets/images/home/fact-9-w.jpg"

const IndexPage = () => (
  <Layout lang="ar">
    <SEO title="Home" lang="ar" />
    <main>
      <HeroSection lang={"ar"}></HeroSection>
      <div className="page-main-area">
        <section id="home-about" className="relative pt5-ns">
          <div className="large-container">
            <div className="standard-container transparent-bg flex flex-column flex-row-ns">
              <div className="w-100 w-60-ns pl5-ns pb5-ns z-index-10">
                <div>
                  <h2 className="relative">
                    نبذة عن مشروع الإمارات لاستكشاف المريخ
                    <div className="text-underline-heavy"></div>
                  </h2>
                  <p className="mt5">
                    سيقدم مشروع الإمارات لاستكشاف المريخ “مسبار الأمل”، أول صورة
                    متكاملة للغلاف الجوي للمريخ، عندما يصل إلى الكوكب الأحمر عام
                    2021، في مهمة تستمر لمدة سنة مريخية واحدة كما سيساعد على
                    الإجابة على أسئلة علمية رئيسية حول الغلاف الجوي للمريخ
                    وأسباب فقدان غازي الهيدروجين والأكسجين من غلافه الجوي.
                  </p>
                </div>
                <div className="negative-margin-left-150 pl5-ns pt5-ns">
                  <h3>الهدف</h3>
                  <p className="uk-text-bold text-light">
                    تقديم
                    <span className="text-highlight-red">
                      {" "}
                      أول صورة متكاملة{" "}
                    </span>
                    للغلاف الجوي للمريخ
                  </p>
                  <div className="flex flex-column mv4">
                    <div className="stacked-card flex justify-between items-center">
                      <div className="w-20 flex justify-center items-center">
                        <span
                          className="uk-text-lead text-highlight-brown stacked-card-count font-nostromo uk-text-bold"
                          style={{ fontWeight: "500" }}
                        >
                          01
                        </span>
                      </div>
                      <div className="w-80">
                        <p>
                          تكوين فهم أعمق حول التغيرات المناخية على سطح كوكب
                          المريخ، ورسم خارطة توضح طبيعة طقسه الحالي عبر دراسة
                          الطبقة السفلى من غلافه الجوي.
                        </p>
                      </div>
                    </div>
                    <div className="stacked-card flex justify-between items-center">
                      <div className="w-20 flex justify-center items-center">
                        <span
                          className="uk-text-lead text-highlight-brown stacked-card-count font-nostromo"
                          style={{ fontWeight: "500" }}
                        >
                          02
                        </span>
                      </div>
                      <div className="w-80">
                        <p>
                          دراسة تأثير التغيرات المناخية على المريخ في تشكيل
                          ظاهرة هروب غازي الأكسجين والهيدروجين من غلافه الجوي
                          عبر دراسة العلاقة بين طبقات الغلاف الجوي السفلية
                          والعلوية.
                        </p>
                      </div>
                    </div>
                    <div className="stacked-card flex justify-between items-center">
                      <div className="w-20 flex justify-center items-center">
                        <span
                          className="uk-text-lead text-highlight-brown stacked-card-count font-nostromo"
                          style={{ fontWeight: "500" }}
                        >
                          03
                        </span>
                      </div>
                      <div className="w-80">
                        <p>
                          إجراء دراسات معمقة حول ظاهرة هروب غازي الأكسجين
                          والهيدروجين من الغلاف الجوي لكوكب المريخ، ومعرفة أسباب
                          حدوثها.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-100 w-40-ns">
                <div className="animated-image-container">
                  <img src={imgHomeSectionOne} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="large-container relative">
            <img
              src={imgMarsBlur}
              className="absolute left-1 top-1 h-100 w-auto"
              alt=""
            />
            <div className="standard-container transparent-bg flex flex-column flex-row-ns">
              <div className="w-100 w-50-ns">
                <img
                  src={imgHomeSatellite}
                  className="negative-margin-right-150 home-satellite"
                  alt=""
                />
              </div>
              <div className="w-100 w-50-ns">
                <div className="animated-tab-head">
                  <div>
                    <h2 className="text-white">حقائق المهمة</h2>
                  </div>
                </div>
                <table className="uk-table uk-table-responsive uk-table-small home-custom-table">
                  <tbody className="text-white">
                    <tr>
                      <td className="text-highlight-brown">اسم المھمة:</td>
                      <td>مشروع الإمارات لاستكشاف المریخ</td>
                    </tr>
                    <tr>
                      <td className="text-highlight-brown">اسم المسبار:</td>
                      <td>مسبار الأمل</td>
                    </tr>
                    <tr>
                      <td className="text-highlight-brown">الإطلاق:</td>
                      <td>20 يوليو 2020، 01:58 صباحاً (بتوقيت دبي)</td>
                    </tr>
                    <tr>
                      <td className="text-highlight-brown">الموقع:</td>
                      <td>مركز تانیغاشیما الفضائي، الیابان</td>
                    </tr>
                    <tr>
                      <td className="text-highlight-brown">
                        المسافة التي
                        <br /> سيقطعها المسبار:
                      </td>
                      <td style={{ verticalAlign: "bottom" }}>
                        493.5 مليون كيلومتر
                      </td>
                    </tr>
                    <tr>
                      <td className="text-highlight-brown">مدة المھمة:</td>
                      <td>سنة مریخیة واحدة (حوالي سنتین من سنوات الأرض)</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="large-container">
            <div className="standard-container transparent-bg">
              <div className="uk-inline">
                <video
                  src="https://emm-f548.kxcdn.com/home-launch-ar.mp4"
                  poster={imgVideoPlaceholder}
                  width="100%"
                  height="500px"
                  loop
                  playsinline
                  controls
                  uk-video
                ></video>
              </div>
            </div>
          </div>
        </section>
        <section id="why-mars" className="relative">
          <img
            src={imgEarthBlur}
            className="uk-overlay uk-position-left uk-postition-top h-auto w-50 pt6-ns"
            alt=""
          />
          <div className="large-container">
            <div className="standard-container transparent-bg">
              <div className="flex flex-column flex-row-ns">
                <div className="w-100 w-50-ns pl5-ns pt6">
                  <h2 className="relative">
                    لماذا المريخ؟
                    <div className="text-underline-heavy"></div>
                  </h2>
                  <p
                    className="uk-text-lead text-highlight-brown font-nostromo"
                    style={{ fontWeight: "500" }}
                  >
                    لماذا نستكشف المريخ؟
                  </p>
                  <p className="mv4">
                    لقد سيطر الكوكب الأحمر على خيال البشر لقرون عدة. لكننا الآن
                    أصبحنا نعرف عنه الكثير، ونمتلك الرؤية والتكنولوجيا اللازمة
                    لاستكشاف المزيد عنه. كما أنه أصبح من البديهي أن نستكشف
                    المريخ وذلك لأسباب عدة، بداية من سعينا الدائم لمعرفة ما إذا
                    كانت هناك حياة خارج الأرض وصولاً إلى تمدد الحضارة البشرية
                    إلى كواكب أخرى يوماً ما، كما يمكننا الاستفادة من المريخ في
                    تحسين حياة الجنس البشري في المستقبل.
                  </p>
                </div>
                <div className="w-100 w-50-ns">
                  <img
                    src={imgHomeSectionTwo}
                    alt=""
                    className="uk-align-right w-100 h-auto"
                  />
                </div>
              </div>
              <div className="ph4-ns relative pt5">
                <div
                  className="uk-position-relative uk-visible-toggle uk-light mt4"
                  data-tabindex="-1"
                  data-uk-slider="clsActivated: uk-transition-active; center: true "
                >
                  <div className="uk-position-relative">
                    <div className="uk-slider-container uk-light">
                      <ul className="uk-slider-items uk-grid uk-grid-small">
                        <li className="uk-width-1-1">
                          <div className="uk-panel pa4-ns animated-text-trigger">
                            <div className="animated-image-container">
                              <img src={imgFactOne} alt="" />
                            </div>
                            <div className="uk-position-center uk-text-center uk-position-center-rtl">
                              <h2 className="fact-slider-heading">
                                <span className="span-underline">
                                  {" "}
                                  ثاني أصغر كوكب{" "}
                                </span>
                                في المجموعة الشمسية
                              </h2>
                              {/* <p uk-slider-parallax="x: 200,-200">Lorem ipsum dolor sit amet.</p> */}
                            </div>
                          </div>
                        </li>
                        <li className="uk-width-1-1">
                          <div className="uk-panel pa4-ns">
                            <div className="animated-image-container">
                              <img src={imgFactTwo} alt="" />
                            </div>
                            <div className="uk-position-center uk-text-center uk-position-center-rtl">
                              <h2 className="fact-slider-heading">
                                <span className="span-underline">
                                  الكوكب الرابع في
                                </span>
                                <br /> من حيث البعد عن الشمس
                                {/* <span className="span-underline"> الكوكب الرابع في </span>
                                       الترتيب من حيث 
                                      مسافة البعد عن الشمس */}
                              </h2>
                            </div>
                          </div>
                        </li>
                        <li className="uk-width-1-1">
                          <div className="uk-panel pa4-ns">
                            <div className="animated-image-container">
                              <img src={imgFactThree} alt="" />
                            </div>
                            <div className="uk-position-center uk-text-center uk-position-center-rtl">
                              <h2 className="fact-slider-heading">
                                <span className="span-underline">
                                  {" "}
                                  اليوم على المريخ{" "}
                                </span>
                                يزيد قليلاً عن
                                <br /> 24 ساعة
                              </h2>
                              {/* <p uk-slider-parallax="x: 200,-200">Lorem ipsum dolor sit amet.</p> */}
                            </div>
                          </div>
                        </li>
                        <li className="uk-width-1-1">
                          <div className="uk-panel pa4-ns">
                            <div className="animated-image-container">
                              <img src={imgFactFour} alt="" />
                            </div>
                            <div className="uk-position-center uk-text-center uk-position-center-rtl">
                              <h2 className="fact-slider-heading">
                                <span className="span-underline">
                                  السنة المريخية
                                </span>{" "}
                                تساوي <br />
                                687 يوماً
                              </h2>
                              {/* <p uk-slider-parallax="x: 200,-200">Lorem ipsum dolor sit amet.</p> */}
                            </div>
                          </div>
                        </li>
                        <li className="uk-width-1-1">
                          <div className="uk-panel pa4-ns">
                            <div className="animated-image-container">
                              <img src={imgFactFive} alt="" />
                            </div>
                            <div className="uk-position-center uk-text-center uk-position-center-rtl">
                              <h2 className="fact-slider-heading">
                                المريخ
                                <span className="span-underline">
                                  {" "}
                                  كوكب صخري
                                </span>
                              </h2>
                              {/* <p uk-slider-parallax="x: 200,-200">Lorem ipsum dolor sit amet.</p> */}
                            </div>
                          </div>
                        </li>
                        <li className="uk-width-1-1">
                          <div className="uk-panel pa4-ns">
                            <div className="animated-image-container">
                              <img src={imgFactSix} alt="" />
                            </div>
                            <div className="uk-position-center uk-text-center uk-position-center-rtl">
                              <h2 className="fact-slider-heading">
                                <span className="span-underline">
                                  {" "}
                                  لديه قمرين{" "}
                                </span>
                                يدوران حوله
                              </h2>
                              {/* <p uk-slider-parallax="x: 200,-200">Lorem ipsum dolor sit amet.</p> */}
                            </div>
                          </div>
                        </li>
                        <li className="uk-width-1-1">
                          <div className="uk-panel pa4-ns">
                            <div className="animated-image-container">
                              <img src={imgFactSeven} alt="" />
                            </div>
                            <div className="uk-position-center uk-text-center uk-position-center-rtl">
                              <h2 className="fact-slider-heading">
                                المريخ لديه <br />
                                <span className="span-underline">
                                  {" "}
                                  غلافاً جوياً رقيقاً
                                </span>
                              </h2>
                            </div>
                          </div>
                        </li>
                        <li className="uk-width-1-1">
                          <div className="uk-panel pa4-ns">
                            <div className="animated-image-container">
                              <img src={imgFactEight} alt="" />
                            </div>
                            <div className="uk-position-center uk-text-center uk-position-center-rtl">
                              <h2 className="fact-slider-heading">
                                يظهر المريخ باللون الأحمر بسبب
                                <span className="span-underline">
                                  {" "}
                                  تأكسد
                                </span>{" "}
                                <br />
                                <span className="span-underline">
                                  الحديد المنتشر في تربته
                                </span>
                              </h2>
                              {/* <p uk-slider-parallax="x: 200,-200">Lorem ipsum dolor sit amet.</p> */}
                            </div>
                          </div>
                        </li>
                        <li className="uk-width-1-1">
                          <div className="uk-panel pa4-ns">
                            <div className="animated-image-container">
                              <img src={imgFactNine} alt="" />
                            </div>
                            <div className="uk-position-center uk-text-center uk-position-center-rtl">
                              <h2 className="fact-slider-heading">
                                المريخ اليوم لا يمتلك
                                <br />
                                <span className="span-underline">
                                  مجالاً مغناطيسياً
                                </span>
                              </h2>
                              {/* <p uk-slider-parallax="x: 200,-200">Lorem ipsum dolor sit amet.</p> */}
                            </div>
                          </div>
                        </li>
                      </ul>

                      <div className="uk-hidden@s uk-light uk-text-center">
                        <a
                          className="uk-position-small custom-slide-nav"
                          href="#"
                          data-uk-slidenav-previous
                          data-uk-slider-item="previous"
                        >
                          السابق
                        </a>
                        <a
                          className="uk-position-small custom-slide-nav"
                          href="#"
                          data-uk-slidenav-next
                          data-uk-slider-item="next"
                        >
                          التالي
                        </a>
                      </div>

                      <div className="uk-visible@s">
                        <a
                          className="uk-position-center-left-out uk-position-small custom-slide-nav"
                          href="#"
                          data-uk-slidenav-previous
                          data-uk-slider-item="previous"
                        >
                          السابق
                        </a>
                        <a
                          className="uk-position-center-right-out uk-position-small custom-slide-nav"
                          href="#"
                          data-uk-slidenav-next
                          data-uk-slider-item="next"
                        >
                          التالي
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="absolute top-0 left-0 fact-text">حقائق</div>
              </div>
            </div>
          </div>
        </section>
        <section id="people">
          <div className="large-container pb6">
            <div className="standard-container">
              <div className="">
                <h2 className="relative uk-text-center">
                  فريق المهمة
                  <div
                    className="text-underline-heavy"
                    style={{ marginLeft: "auto", marginRight: "auto" }}
                  ></div>
                </h2>
              </div>
              <div className="custom-slider ph4-ns" data-uk-slider>
                <div className="uk-position-relative">
                  <div className="uk-slider-container uk-light">
                    <ul className="uk-slider-items">
                      <li>
                        <img src={imgPeopleOne} alt="" />
                      </li>
                      <li>
                        <img src={imgPeopleTwo} alt="" />
                      </li>
                      <li>
                        <img src={imgPeopleThree} alt="" />
                      </li>
                      <li>
                        <img src={imgPeopleFour} alt="" />
                      </li>
                      {typeof imgPeopleFive !== "undefined" && (
                        <li>
                          <img src={imgPeopleFive} alt="" />
                        </li>
                      )}
                      {typeof imgPeopleSix !== "undefined" && (
                        <li>
                          <img src={imgPeopleSix} alt="" />
                        </li>
                      )}
                      <li>
                        <img src={imgPeopleSeven} alt="" />
                      </li>
                    </ul>
                  </div>

                  <div className="uk-hidden@s uk-light uk-text-center">
                    <a
                      className="uk-position-small custom-slide-nav"
                      href="#"
                      data-uk-slidenav-previous
                      data-uk-slider-item="previous"
                    >
                      السابق
                    </a>
                    <a
                      className="uk-position-small custom-slide-nav"
                      href="#"
                      data-uk-slidenav-next
                      data-uk-slider-item="next"
                    >
                      التالي
                    </a>
                  </div>

                  <div className="uk-visible@s">
                    <a
                      className="uk-position-center-left-out uk-position-small custom-slide-nav"
                      href="#"
                      data-uk-slidenav-previous
                      data-uk-slider-item="previous"
                    >
                      السابق
                    </a>
                    <a
                      className="uk-position-center-right-out uk-position-small custom-slide-nav"
                      href="#"
                      data-uk-slidenav-next
                      data-uk-slider-item="next"
                    >
                      التالي
                    </a>
                  </div>
                </div>

                <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
              </div>
            </div>
          </div>
        </section>
        {/* <section id="partners">
            <div className="large-container">
              <div className="standard-container">
                <div className="">
                  <h2 className="relative uk-text-center">
                  الشركاء
                    <div className="text-underline-heavy" style={{marginLeft: "auto", marginRight: "auto"}}></div>
                  </h2>
                </div>
                <div className="ph4-ns mv5" data-uk-slider>

                  <div className="uk-position-relative">

                      <div className="uk-slider-container uk-light">
                          <ul className="uk-slider-items uk-grid-medium">
                              <li>
                                  <img src={imgLogoHere} alt=""/>
                              </li>
                              <li>
                                  <img src={imgLogoHere} alt=""/>
                              </li>
                              <li>
                                  <img src={imgLogoHere} alt=""/>
                              </li>
                              <li>
                                  <img src={imgLogoHere} alt=""/>
                              </li>
                              <li>
                                  <img src={imgLogoHere} alt=""/>
                              </li>
                          </ul>
                      </div>

                      <div className="uk-hidden@s uk-light">
                          <div className="uk-position-center-left-out uk-position-small cusrsor-pointer" data-uk-slidenav-previous data-uk-slider-item="previous"></div>
                          <div className="uk-position-center-right-out uk-position-small cusrsor-pointer"  data-uk-slidenav-next data-uk-slider-item="next"></div>
                      </div>

                      <div className="uk-visible@s">
                          <div className="uk-position-center-left-out uk-position-small cusrsor-pointer"  data-uk-slidenav-previous data-uk-slider-item="previous"></div>
                          <div className="uk-position-center-right-out uk-position-small cusrsor-pointer" data-uk-slidenav-next data-uk-slider-item="next"></div>
                      </div>

                  </div>
                </div>
              </div>
              
                    
            </div>
        </section>            */}
      </div>
    </main>
  </Layout>
)

export default IndexPage
